@import '../resources/vars';
@import '../resources/mixins';

.container {
  position: relative;

  height: var(--app-custom-progress-bar-height, 8px);
  width: var(--app-custom-progress-bar-width,100%);
  padding: var(--app-custom-progress-bar-padding);
  margin: var(--app-custom-progress-bar-margin);
  background: var(--app-custom-progress-bar-background, #dfe3e6);
  border: var(--app-custom-progress-bar-border);
  border-radius: var(--app-custom-progress-bar-border-radius, 4px);
  box-shadow: var(--app-custom-progress-bar-box-shadow);

}

.label {
  position: absolute;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: var(--app-custom-progress-label-color, var(--coach-bars-label-colour));
  font-size: var(--app-custom-progress-label-font-size, var(--coach-bars-label-font-size));
  font-family: var(--app-custom-progress-label-font-family, var(--coach-bars-label-font-family));
  font-weight: var(--app-custom-progress-label-font-weight, var(--coach-bars-label-font-weight));
  font-style: var(--app-custom-progress-label-font-style, var(--coach-bars-label-font-style));
  line-height: var(--app-custom-progress-label-line-height, var(--coach-bars-label-line-height));
  letter-spacing: var(--app-custom-progress-label-letter-spacing, var(--coach-bars-label-letter-spacing));
  text-transform: var(--app-custom-progress-label-text-transform, var(--coach-bars-label-text-transform));
  margin: var(--app-custom-progress-label-margin, var(--coach-bars-label-margin));
  padding: var(--app-custom-progress-label-padding, var(--coach-bars-label-padding));
}

.filler {

  background: var(--app-custom-progress-filler-background, #33396c);
  border: var(--app-custom-progress-filler-border);
  border-radius: inherit;
  //border-radius: var(--app-custom-progress-filler-border-radius);
  box-shadow: var(--app-custom-progress-filler-box-shadow);
  height: 100%;
  transition: width 1.5s ease-out;
}
