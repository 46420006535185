@import '../resources/vars';
@import '../resources/mixins';

.link {
  cursor: pointer;
  width: 100%;
}

.card {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  box-shadow: var(--card-box-shadow);
  border-radius: var(--card-border-radius, 0);
  border: var(--card-border, none);
  margin: var(--card-margin, 0);
  padding: var(--card-padding, 0);
}

.header {
  position: relative;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: var(--card-play-icon-display);

  svg {
    width: var(--card-play-icon-size);
    height: var(--card-play-icon-size);

    path {
      fill: var(--card-play-icon-color);
    }
  }
}

.content {
  height: var(--card-image-height, 100%);
  width: var(--card-image-width, 100%);
  object-fit: var(--card-image-object-fit, cover);
  object-position: var(--card-image-object-position, center);
  aspect-ratio: var(--card-image-aspect-ratio, 16/9);
}

.body {
  flex: 1;
  background: var(--card-body-background, transparent);
  padding: var(--card-body-padding, 16px);

  @include respond-to(sm, up) {
    padding: var(--card-body-padding-m, 16px);
  }

  @include respond-to(lg, up) {
    padding: var(--card-body-padding-l, 16px);
  }
}

.heading {
  @include with-styled-text(card-heading);

  display: var(--card-heading-display, block);
  padding: var(--card-heading-padding, 0);

  @include respond-to(sm, up) {
    padding: var(--card-heading-padding-m, 0);
    font-size: var(--card-heading-font-size-m, var(--card-heading-font-size));
  }

  @include respond-to(lg, up) {
    padding: var(--card-heading-padding-l, 0);
    font-size: var(--card-heading-font-size-l, var(--card-heading-font-size));
  }
}

.subTitle {
  @include with-styled-text(card-sub-heading);

  display: var(--card-sub-heading-display, none);
  padding: var(--card-sub-heading-padding, 0);

  @include respond-to(sm, up) {
    padding: var(--card-sub-heading-padding-m, 0);
    font-size: var(--card-sub-heading-font-size-m, var(--card-sub-heading-font-size));
  }

  @include respond-to(lg, up) {
    padding: var(--card-sub-heading-padding-l, 0);
    font-size: var(--card-sub-heading-font-size-l, var(--card-sub-heading-font-size));
  }
}

.excerpt {
  @include with-styled-text(card-excerpt);

  display: var(--card-excerpt-display, block);
  padding: var(--card-excerpt-padding, 0);

  @include respond-to(sm, up) {
    padding: var(--card-excerpt-padding-m, 0);
    font-size: var(--card-excerpt-font-size-m, var(--card-excerpt-font-size));
  }

  @include respond-to(lg, up) {
    padding: var(--card-excerpt-padding-l, 0);
    font-size: var(--card-excerpt-font-size-l, var(--card-excerpt-font-size));
  }
}

.footer {
  align-items: center;
  display: var(--card-footer-display, flex);
  margin: var(--card-footer-margin, 0);
  padding: var(--card-footer-padding, 3px 16px 19px);

  @include respond-to(sm, up) {
    padding: var(--card-footer-padding-m, 3px 16px 19px);
  }

  @include respond-to(lg, up) {
    padding: var(--card-footer-padding-l, 3px 16px 19px);
  }
}

.label-icon {
  display: var(--card-label-icon-display, block);

  svg {
    width: var(--card-label-icon-size, 24px);
    height: var(--card-label-icon-size, 24px);

    path {
      fill: var(--card-label-icon-color, #555555);
    }
  }
}

.label-wrapper {
  display: flex;
  flex: 1;
  padding: var(--card-label-wrapper-padding);

  @include respond-to(sm, up) {
    padding: var(--card-label-wrapper-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--card-label-wrapper-padding-l);
  }
}

.label {
  background: var(--card-label-background);
  border: var(--card-label-border);
  border-radius: var(--card-label-border-radius);

  @include with-styled-text(card-label);

  text-transform: var(--card-label-text-transform);
  text-align: var(--card-label-text-align);
  color: var(--card-label-colour, #555555);
  padding: var(--card-label-padding, 0 8px);

  @include respond-to(sm, up) {
    padding: var(--card-label-padding-m, 0 8px);
    font-size: var(--card-label-font-size-m, var(--card-label-font-size));
  }

  @include respond-to(lg, up) {
    padding: var(--card-label-padding-l, 0 8px);
    font-size: var(--card-label-font-size-l, var(--card-label-font-size));
  }
}

.action {
  --app-custom-btn-background: transparent;
  --app-custom-btn-width: 24px;
  --btn-negative-border: none;
  --app-btn-label-padding: 0;
  --app-custom-btn-height: var(--card-action-icon-size, 24px);

  @include respond-to(sm, up) {
    --app-custom-btn-height-m: var(--card-action-icon-size, 24px);
  }

  @include respond-to(lg, up) {
    --app-custom-btn-height-l: var(--card-action-icon-size, 24px);
  }

  --app-btn-icon-height: 24px;
  --app-btn-icon-margin: 24px;
}

.action-icon {
  display: var(--card-action-icon-display, block);

  svg {
    width: var(--card-action-icon-size, 24px);
    height: var(--card-action-icon-size, 24px);

    path {
      fill: var(--card-action-icon-color, #EB2264);
    }
  }
}
