@import '../../resources/vars';
@import '../../resources/mixins';

.title {
  color: var(--typo-h2-colour);
  font-family: var(--typo-h2-font-face);
  font-size: var(--typo-h2-font-size);
  font-style: var(--typo-h2-font-style);
  font-weight: var(--typo-h2-font-weight);
  letter-spacing: var(--typo-h2-letter-spacing);
  line-height: var(--typo-h2-line-height);
  margin: var(--typo-h2-margin);
  text-align: var(--typo-h2-text-align);
  text-transform: var(--typo-h2-text-transform);
}
