@import '../resources/vars';
@import '../resources/mixins';

.carousel {
  --app-card-slider-button-height: 0px;
  // .slick-list
  > div {
    &[class='slick-list'] {
      margin: var(--app-slider-slick-list-margin);
      overflow-y: visible;
      overflow-x: clip;
    }

    // .slick-track
    > div {
      display: flex;
      align-items: stretch;
      gap: var(--app-card-slider-card-gap);

      // .slick-slide
      > div {
        display: flex !important;
        height: unset;

        // single child div
        > div {
          display: flex;
          width: 100%;
          justify-content: center;

          // Slider items
          > article, > div {
            width: var(--app-card-slider-card-width, 100%);
            height: var(--app-card-slider-card-height, 100%);
          }
        }
      }
    }
  }
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: initial;
  padding: var(--app-card-slider-dots-padding);

  ul {
    margin: 0;
    display: flex;
    vertical-align: center;

    li {
      margin: var(--app-card-slider-dots-gap, 12px);
      width: var(--app-card-slider-dots-size);
      height: var(--app-card-slider-dots-size);
    }

    li button {
      padding: 0;
      width: var(--app-card-slider-dots-size);
      height: var(--app-card-slider-dots-size);
    }

    li button:before {
      width: var(--app-card-slider-dots-size);
      height: var(--app-card-slider-dots-size);
      font-size: var(--app-card-slider-dots-size);
      color: var(--app-card-slider-dots-off-color);
      opacity: 1;
      line-height: 1;
    }
  }

  [class="slick-active"] button:before {
    color: var(--app-card-slider-dots-on-color) !important;
    opacity: 1 !important;
  }
}

.button {
  box-shadow: var(--repeater-pager-icons-box-shadow);
  border-radius: var(--repeater-pager-icons-border-radius);
  padding: var(--repeater-pager-icons-padding);

  @include respond-to(sm, up) {
    padding: var(--repeater-pager-icons-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--repeater-pager-icons-padding-l);
  }

  border: var(--app-custom-repeater-pager-icons-border);
  background: var(--app-custom-repeater-pager-icons-background);

  svg {
    height: var(--app-card-slider-button-icon-size);
    width: var(--app-card-slider-button-icon-size);
  }

  path {
    fill: var(--app-card-slider-button-icon-color);
  }

  &[aria-disabled="true"] {
    path {
      fill-opacity: 0.5;
    }
  }
}

.story-slick-arrow {
  z-index: 3;
  position: absolute;

  height: var(--app-card-slider-button-height);
  width: var(--app-card-slider-button-width);
  top: var(--app-card-slider-button-top);
  background: var(--app-card-slider-button-background);
  border: var(--app-card-slider-button-border);
  border-radius: var(--app-card-slider-button-border-radius);
  box-shadow: var(--app-card-slider-button-box-shadow);
}

.prev {
  left: var(--app-card-slider-button-prev-left);
}

.next {
  right: var(--app-card-slider-button-next-right);
}
