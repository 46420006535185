@import '../../resources/vars';
@import '../../resources/mixins';

.image{
  width: 100%;
  height: 100%;

  aspect-ratio: 16/9;
  flex: 1 1;
}
