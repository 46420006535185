@import '../resources/vars';
@import '../resources/mixins';

.container {
  border-top: var(--accordion-v2-border-top);
  border-bottom: var(--accordion-v2-border-bottom);
  border-left: var(--accordion-v2-border-left);
  border-right: var(--accordion-v2-border-right);
  border-radius: var(--accordion-v2-border-radius);
  padding: var(--accordion-v2-padding);
  overflow: hidden;
}

.item {
  background-color: var(--accordion-background);
  box-shadow: var(--accordion-box-shadow);
  border-top: var(--accordion-border-top);
  border-bottom: var(--accordion-border-bottom);
  border-left: var(--accordion-border-left);
  border-right: var(--accordion-border-right);
  border-radius: var(--accordion-border-radius);
  padding: var(--accordion-padding);
  margin: var(--accordion-margin);

  display: flex;
  flex-direction: column;
  gap: var(--accordion-item-gap);

  @include respond-to(sm, up) {
    padding: var(--accordion-padding-m);
    gap: var(--accordion-item-gap-m, var(--accodrion-item-gap));
  }

  @include respond-to(lg, up) {
    padding: var(--accordion-padding-l);
    gap: var(--accordion-item-gap-l, var(--accodrion-item-gap));
  }

  border-top: var(--accordion-border-top);
  &:not(:last-child) {
    border-bottom: var(--accordion-border-bottom);
  }
}
.wrapper {
  display: flex;
  flex: 1;
  flex-direction: var(--accordion-flex-direction);
  gap:  var(--accordion-gap, 8px);

  @include respond-to(sm, up) {
    flex-direction: var(--accordion-flex-direction-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--accordion-flex-direction-l);
  }
}

.trigger {
  align-items: var(--accordion-v2-header-align);
  background-color: var(--accordion-v2-header-bg-color);
  border-color: var(--accordion-v2-header-border-color);
  border-style: var(--accordion-v2-header-border-style);
  border-width: var(--accordion-v2-header-border-width);
  display: flex;
  flex-direction: var(--accordion-v2-header-direction);
  justify-content: var(--accordion-v2-header-justify);
  margin: var(--accordion-v2-header-margin);
  padding: var(--accordion-v2-header-padding);

  @at-root :global([aria-expanded='true']) & {
    background-color: var(--accordion-v2-header-bg-color-open);
  }
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  order: 1;
}

.icon {
  background-color: var(--accordion-v2-icon-bg-color);
  border: var(--accordion-v2-icon-bg-border);
  margin: var(--accordion-v2-icon-margin);

  svg {
    height: var(--accordion-v2-icon-size);
    width: var(--accordion-v2-icon-size);
  }

  path {
    fill: var(--accordion-v2-icon-color);
  }

  @at-root :global([aria-expanded='true']) & {
    background-color: var(--accordion-v2-icon-bg-color-open);
    border: var(--accordion-v2-icon-bg-border-open);

    path {
      fill: var(--accordion-v2-icon-color-open, var(--accordion-v2-icon-color));
    }
  }
}

.title {
  @include with-styled-text(accordion-v2-title);

  @at-root :global([aria-expanded='true']) & {
    color: var(--accordion-v2-title-colour-open);
  }
}

.sub-title {

}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--accordion-v2-content-gap);
  padding: var(--accordion-v2-content-padding);

  @include respond-to(sm, up) {
    gap: var(--accordion-v2-content-gap-m, var(--accordion-v2-content-gap));
    padding: var(--accordion-v2-content-padding-m, var(--accordion-v2-content-padding));
  }

  @include respond-to(lg, up) {
    gap: var(--accordion-v2-content-gap-l, var(--accordion-v2-content-gap));
    padding: var(--accordion-v2-content-padding-l, var(--accordion-v2-content-padding));
  }
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: var(--accordion-content-flex-direction);
  justify-content: var(--accordion-content-justify-content);
  gap: var(--accordion-content-gap);

  @include respond-to(sm, up) {
    flex-direction: var(--accordion-content-flex-direction-m);
    justify-content: var(--accordion-content-justify-content-m);
    gap: var(--accordion-content-gap-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--accordion-content-flex-direction-l);
    justify-content: var(--accordion-content-justify-content-l);
    gap: var(--accordion-content-gap-l);
  }
}

.words {
  display: flex;
  align-items: center;
  gap: var(--accordion-words-gap);
  flex-grow: var(--accordion-words-grow);

  @include respond-to(sm, up) {
    gap: var(--accordion-words-gap-m);
    flex-grow: var(--accordion-words-grow-m);
  }

  @include respond-to(lg, up) {
    gap: var(--accordion-words-gap-l);
    flex-grow: var(--accordion-words-grow-l);
  }
}

.actions {
  display: flex;
  flex-grow: var(--app-custom-flex-grow);
  flex-basis: var(--app-custom-flex-basis);
  flex-shrink: var(--app-custom-flex-shrink);

  @include respond-to(sm, up) {
    flex-grow: var(--app-custom-flex-grow-m);
    flex-basis: var(--app-custom-flex-basis-m);
    flex-shrink: var(--app-custom-flex-shrink-m);
  }

  @include respond-to(lg, up) {
    flex-grow: var(--app-custom-flex-grow-l);
    flex-basis: var(--app-custom-flex-basis-l);
    flex-shrink: var(--app-custom-flex-shrink-l);
  }
}
